import React from 'react'
import './FooterBar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faEnvelope, faPhone, faGlobe } from '@fortawesome/free-solid-svg-icons'

const FooterBar = () => {
  return (
    <div className='footerbar'>
      <div className='footerbar-up-side'>
        <div className='footer-content-title-main'>
          <div className='footer-content-title'>
            <img src="https://firebasestorage.googleapis.com/v0/b/northsea-ff67b.appspot.com/o/Images%2FHomePage%2Fsl-logo.png?alt=media&token=5cb34826-a468-4fb1-ad83-d258d97d9f86" alt="nation" height="60px"/>
            <p className='company-name'>North Sea Ltd</p>
            <img src="https://firebasestorage.googleapis.com/v0/b/northsea-ff67b.appspot.com/o/Images%2FHomePage%2Flogo512.png?alt=media&token=8c5048f9-103f-49ef-8cf5-2d6f60dd33e5" alt="logo" height="50px" />
          </div>
          <div className='govement-infor'>
            <img src="https://firebasestorage.googleapis.com/v0/b/northsea-ff67b.appspot.com/o/Images%2FHomePage%2Fgic_en.gif?alt=media&token=726134cc-f694-48a9-9c36-b4f88c594f9b" alt="info" height="60px" />
          </div>
        </div>
        <div className='footer-content-link'>
          <h3>Quick Link</h3>

          <div className='footer-quick-links'>
            <a href="/">Home</a>
            <a href="/aboutus">About Us</a>
            <a href="/our-products">Our Products</a>
            <a href="/announcements">Announcements</a>
            <a href="/gallery">Gallery</a>
            <a href="/contact-us">Contact Us</a>
          </div>
        </div>
        <div className='footer-contact'>
          <h3>Contact us</h3>
          <a href="/contact-us"
          className='footer-contact-us' style={{ maxWidth: "260px" }}>
            <FontAwesomeIcon icon={faLocationDot} color='white' size='26px' className='footer-contact-us-icon' />Northsea Ltd, Ground Floor, Ministry of Fisheries, New Secretariat, Maligawatta, Colombo -10
          </a>
          <a href="mailto:info@northsea.lk"
          className='footer-contact-us'>
            <FontAwesomeIcon icon={faEnvelope} size='26px' className='footer-contact-us-icon' />info@northsea.lk
          </a>
          <a href="https://www.northsea.lk" className='footer-contact-us'>
            <FontAwesomeIcon icon={faGlobe} size='26px' className='footer-contact-us-icon' />https://www.northsea.lk
          </a>
          <a href="tel:0114343345"
           className='footer-contact-us'>
            <FontAwesomeIcon icon={faPhone} size='26px' className='footer-contact-us-icon' />0114 343 345 / 0114 343 447
          </a>
        </div>

      </div>
      <div className='footer-down-bar'>
        <p className='rights-text'>All  rights reserved. &copy; 2024 Copyright. <spa className='company'>North Sea Ltd. Desined & Developed by <a 
        href='https://deepbees.com/'
        style = {{color: 'white'}}
        >DeepBees (PVT) LTD</a></spa></p>
      </div>
    </div>
  )
}

export default FooterBar
